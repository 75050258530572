import './button.scss';
import Loader from './loader';

const Button = (props) => {

  return (
    <div className={`button ${props.classes.join(" ")}`} onClick={props.handler} style={{pointerEvents: props.loading ? "none" : "", display: props.btnHidden ? 'none' : 'flex'}}>
      {!props.loading && <span>{props.text}</span>}
      {props.loading && <Loader width="35px" dark={true}/>} 
    </div>
  );
}

export default Button;