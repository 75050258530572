import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import './sliderNav.scss';


const SliderNav = (props) => {

  const goToSlide = (index) =>{
    props.slider.slickGoTo(index);
  }

  const prevSlide = () => {
    props.slider.slickPrev();
  }

  const nextSlide = () => {
    props.slider.slickNext();
  }

  return(
    <div className={`slider-nav ${props.class}`}>
      {props.arrows &&
      <div className='slider-nav_arrows'>
        <MdOutlineKeyboardArrowLeft onClick={prevSlide}/>
        <MdOutlineKeyboardArrowRight onClick={nextSlide}/>
      </div>}
      {props.buttons.length > 0 &&
        <div className={`slider-nav_buttons ${props.btnClass}`}>
          <div className='slider-nav_buttons_line'></div>
          {props.buttons.map((item, index) => {
            return(
              <div className={`slider-nav_button ${props.currentSlide === index ? 'slider-nav_button__active' : ''}`} key={index} onClick={() => goToSlide(index)}>
                {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
              </div>
            )
          })}
        </div>}
    </div>
  )
}

export default SliderNav;