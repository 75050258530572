import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";
import "./stsMap.scss";

const mapData = {
  center: [55.75491528043257, 37.55730168493042],
  zoom: 14,
  controls: [],
};

const coordinates = [[55.75491528043257, 37.55730168493042]];

const StsMap = () => {
  return (
    <div className="wrapper-item">
      <YMaps>
        <Map
          defaultState={mapData}
          className="sts-map"
          instanceRef={(ref) => {
            if (ref) {
              ref.behaviors.disable("scrollZoom");
            }
          }}
        >
          {coordinates.map((coordinate, index) => (
            <Placemark geometry={coordinate} key={index} />
          ))}
          <ZoomControl />
        </Map>
      </YMaps>
    </div>
  );
};

export default StsMap;
