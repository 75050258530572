import footerImage from '../assets/footer.png'
import logo_footer from '../assets/logo_footer.png'

const footerData = {
    footerImage: footerImage,
    address: "Москва, Краснопресненская набережная, 12, башня Международная, офис 524",
    contacts: {
        phones: ["+7 495 649 69 51"],
        email: "info@smart-ts.ru"
    },
    logo: logo_footer,
    organizationText: "© Оформление сайта. ООО «Смарт Тим Сервис», 2022"
}

export default footerData;