import "./header.scss";
import React, { createRef } from "react";
import Burger from "./burger";
import logo from "../assets/intro-logo.svg";
import strings from "../localization";
import useWindowDimensions from "../utils/useWindowDimensions";
import { ScreenWidth } from "../utils/screenWidth";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.headerRef = createRef();
    this.headerContainerRef = createRef();
    this.state = {
      scrollY: window.scrollY,
      headersItems: strings.headerItems,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", (event) => this.handleScroll(event));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language != this.props.language) {
      this.setState({ headersItems: strings.headerItems });
    }
  }

  handleScroll = (event) => {
    this.setState({ scrollY: window.scrollY });
    if (window.scrollY >= 50) {
      this.headerRef.current.classList.add("sticky-header");
      this.headerContainerRef.current.classList.add("sticky-header-container");
    } else {
      this.headerRef.current.classList.remove("sticky-header");
      this.headerContainerRef.current.classList.remove(
        "sticky-header-container"
      );
    }
  };

  render() {
    const burgerHandler = (status) => {
      this.props.navBarHandler(status);
    };

    const scrollToHandler = (item, width) => {
      console.log("width", width);
      if (item.path === "#footer") {
        document
          .querySelector(".test")
          .scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        if (item.path) {
          const yOffset =
            (width <= 1000 ? item.smallOffset : item.offset) || -100;
          window.scrollTo({
            top:
              document.querySelector(`${item.path}`).getBoundingClientRect()
                .top +
              window.pageYOffset +
              yOffset,
            behavior: "smooth",
          });
        }
      }
    };

    return (
      <ScreenWidth>
        {(width) => (
          <div className="header-container" ref={this.headerContainerRef}>
            <div className={`header`} ref={this.headerRef}>
              <div className="header-logo">
                <img src={logo}></img>
              </div>
              <div className="header-items">
                {this.state.headersItems.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="header-item"
                        onClick={() => scrollToHandler(item, width)}
                      >
                        {item.title}
                      </div>
                    </React.Fragment>
                  );
                })}
                <div
                  className="header-item localization"
                  onClick={() => this.props.handleLanguageChange()}
                >
                  {this.props.language == "en" ? "ru" : "en"}
                </div>
                <div
                  className="header-item header-item_burger"
                  onClick={() => burgerHandler(true)}
                >
                  <Burger class="burger" />
                </div>
              </div>
            </div>
          </div>
        )}
      </ScreenWidth>
    );
  }
}

export default Header;
