import "./footer.scss";
import footerData from "../data/footerData";
import { createRef, useEffect, useState } from "react";
import strings from "../localization";

const Footer = () => {
  const footerRef = createRef();
  const [heigth, setHeigth] = useState(null);

  useEffect(() => {
    setHeigth(`calc(60vh + ${footerRef.current.clientHeight}px)`);
  }, []);

  return (
    <div className="footer" id="footer">
      <div className="test" style={{ bottom: heigth }}></div>
      <div className="footer-contacts wrapper">
        <div
          className="footer-contacts_image"
          style={{ backgroundImage: `url(${footerData.footerImage})` }}
        ></div>
        <div className="footer-contacts_address">
          <div className="footer_heading">
            {strings.footerData.adress.title}
          </div>
          {strings.footerData.adress.info}
        </div>
        <div className="footer-contacts_email--phones">
          <div className="footer_heading">
            {strings.footerData.contacts.title}
          </div>
          <div className="footer-contacts_items">
            <span>{strings.footerData.contacts.phone}</span>
            <span>{strings.footerData.contacts.email}</span>
          </div>
        </div>
      </div>
      <div className="footer-info" ref={footerRef} id="footerInfo">
        <div className="wrapper footer-info_container">
          <div className="footer-info_logo">
            <img src={footerData.logo}></img>
          </div>
          <div className="footer-info_organization">
            {strings.footerData.organizationText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
