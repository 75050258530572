import "./curses.scss";
import { useState, useEffect } from "react";

import union from "../assets/union.svg";
import settings from "../assets/settings.svg";
import arrow from "../assets/arrow.svg";


function Curses() {

  const showItems = ({ currentTarget }) => {
    const element = currentTarget.parentNode.parentNode.querySelector(
      ".curses__list-items"
    );
    const isShow = element.style.display === "block";
    currentTarget.classList.toggle("rotate");

    if (!isShow) {
      element.style.display = "block";
    } 
    else {
      element.style.display = "none";
    } 
  }

  return (
    <div className="curses wrapper-item" id="curses">
      <div className="wrapper">
        <div className="curses__title wrapper-title">Курсы</div>
        <div className="curses__second-title">
          изучение программного комПлекса modus bi
        </div>
      </div>
      <div className="curses__wrapper">
        <div className="curses__container">
          <div className="curses__bg"></div>
          <div className="wrapper">
            <div className="curses__info">
              <div className="curses__info-title">О курсе</div>
              <div className="curses__info-text">
                В рамках данного курса вам представится возможность освоить все
                этапы работы с Modus BI – от установки и администрирования до
                обработки данных и их визуализации в формате отчётов. Курс
                включает в себя видеоматериалы, практические задания, а также
                текстовые статьи по каждой теме, которые помогут вам наиболее
                эффективно и полно освоить основные аспекты программного
                комплекса Modus BI.
              </div>
              <div className="curses__list">
                <div className="curses__list-item">
                  <div className="curses__list-item-title">
                    Кому подходит
                    <img src={arrow} onClick={showItems} />
                  </div>
                  <div className="curses__list-items">
                    <div className="curses__list-element">
                      <div className="curses__list-element-gr" />
                      БИЗНЕС-АНАЛИТИК
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-gr" /> DATA-ИНЖЕНЕР
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-gr" />
                      СИСТЕМНЫЙ АДМИН
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-gr" />
                      НОВИЧОК ИТ
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-gr" />
                      БИЗНЕС-ПОЛЬЗОВАТЕЛЬ
                    </div>
                  </div>
                </div>
                <div className="curses__list-item">
                  <div className="curses__list-item-title">
                    Что ждёт на курсе
                    <img src={arrow} onClick={showItems} />
                  </div>
                  <div className="curses__list-items">
                    <div className="curses__list-element">
                      <div className="curses__list-element-nr">16</div>
                      видео-уроков в формате онлайн
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-nr">14</div>
                      часов теории и практики
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-nr">3</div>
                      тестовых заданий
                    </div>
                    <div className="curses__list-element">
                      <div className="curses__list-element-nr">2</div>
                      бизнес-кейса
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper curses__form">
          <div className="curses__form-control-wrapper">
            <img src={union} className="curses__form-control-union" />
            <input
              className="curses__form-control"
              placeholder="Укажите Ваш email *"
            />
            <img src={settings} className="curses__form-control-settings" />
          </div>
          <button className="curses__form-button">оставить заявку</button>
        </div>
      </div>
    </div>
  );
}

export default Curses;
 