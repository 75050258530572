import "./partners.scss";
import PartnersItem from "./partnersItem";
import strings from "../localization";
import SliderNav from "./sliderNav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useState, useEffect } from "react";

let items = [
  // { image: require("../assets/ts.png") },
  { image: require("../assets/webtutor.png") },
  { image: require("../assets/logo_modus.png") },
  { image: require("../assets/fsight_new.png") },
  { image: require("../assets/avanpost.png") },
  { image: require("../assets/Group_3945.png") },
  { image: require("../assets/elma365-logo 1 (1).png") },
  { image: require("../assets/logo_rbs (2).png") },
  { image: require("../assets/infomax.png") },
  { image: require("../assets/mega.jpg") },
  { image: require("../assets/qlik.png") },
  { image: require("../assets/Logo_GLARUS_BI.png") },
  { image: require("../assets/Aw.png") },
];

function Partners() {
  const [slides, setSlides] = useState([]);
  const [slider, setSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesShow, setSlidesShow] = useState(1);
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: slidesShow,
    afterChange: (current) => setCurrentSlide(current),
    arrows: false,
  };

  useEffect(() => {
    transformArray(items, Math.floor(window.innerWidth / 400));
  }, []);

  const transformArray = (array, sizeArray) => {
    let size = sizeArray;
    let subarray = [];
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      subarray[i] = array.slice(i * size, i * size + size);
    }
    console.log("subarray", subarray);
    setSlides(subarray);
  };

  const prevSlide = () => {
    slider.slickPrev();
  };

  const nextSlide = () => {
    slider.slickNext();
  };

  return (
    <div className="partners wrapper-item" id="partners">
      <div className="partners-title wrapper-title">
        {strings.partnerTitle}
        <div className="partners-title_wrapper">
          <MdKeyboardArrowLeft
            className="partners-title_slide-nav left"
            onClick={prevSlide}
          />
          <MdKeyboardArrowRight
            className="partners-title_slide-nav"
            onClick={nextSlide}
          />
        </div>
        <SliderNav
          arrows={true}
          buttons={Array.from({ length: slides.length })}
          slider={slider}
          currentSlide={currentSlide}
          class="work-us_slide-nav"
          btnClass="work-us_slide-nav_buttons"
        />
      </div>
      <div className="partners-items">
        <Slider ref={(c) => setSlider(c)} {...settings}>
          {slides.map((slide, index) => {
            return (
              <div key={index}>
                <div className="partners-items_slide">
                  {slide.map((item, i) => (
                    <PartnersItem key={i} image={item.image} />
                  ))}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Partners;
