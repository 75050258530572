import "./services.scss";
import strings from "../localization";
import { useEffect, useState } from "react";

function Services(props) {

  const selectCaseHandler = (caseId) => {
    props.selectCaseHandler(caseId)
  } 

  const [items, setItems] = useState(strings.serviceItems)

  useEffect(() => {
    setItems(strings.serviceItems)
  }, [props.language])

  return (
    <div className="services wrapper-item" id="services">
      <div className="services-title wrapper-title">{strings.serviceTitle}</div>
      <div className="services-items">
        {items.map((m, i) => {
          return (
            <div className="services-item" key={i}>
              <div className="services-item__num">{m.num}</div>
              <div className="services-item__seporator"></div>
              <div className="services-item__content">
                <div className="services-item__title">{m.title}</div>
                <div className="services-item__desc">{m.desc}
                  {m.caseId && <span onClick={() => selectCaseHandler(m.caseId)}>{props.language === "ru" ? "Кейсы" : "Cases"}</span>}
                  {m.action && <span onClick={() => m.action()}>{props.language === "ru" ? "Отправить заявку" : "Submit an application"}</span>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Services;
