import Button from "./button";
import "./contactUs.scss";
import letters from "../assets/letters.svg";
import { BsCheck } from "react-icons/bs";
import { useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Inputmask from "inputmask";
import mail from "../api/mail.js";
import strings from "../localization";

const ContactUs = () => {
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnHidden, setBtnHidden] = useState(false);

  const phoneRef = useRef();

  const phoneInputHandler = (event, setFieldValue) => {
    setFieldValue("phone", event.target.value.replace(/\D/g, ""));
    Inputmask({
      mask: "+7 (999) 999-99-99",
      onBeforeMask: (value) => {
        let processedValue = value;
        if (processedValue[0] === "8" || processedValue[0] === "7") {
          processedValue = processedValue.replace(processedValue[0], "+7");
        }
        return processedValue;
      },
      onBeforePaste: (pastedValue) => {
        if (pastedValue[0] === "8" || pastedValue[0] === "7") {
          pastedValue = pastedValue.replace(pastedValue[0], "+7");
        }
        return pastedValue;
      },
    }).mask(phoneRef.current);
  };

  const checkboxHandler = (setFieldValue) => {
    setFieldValue("agreement", !check);
    setCheck(!check);
  };

  const phoneRegExp = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;

  const validationSchema = yup.object().shape({
    surname: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
    phone: yup.string().required().matches(phoneRegExp),
    agreement: yup.bool().required().oneOf([true]),
  });

  return (
    <div className="contact-us wrapper-item wrapper" id="contactUs">
      <div className="heading  wrapper-title">{strings.contactUsTitle}</div>
      <div className="contact-us-conteiner">
        <div className="contact-us_description">
          <span>{strings.contactUsText}</span>
          <img src={letters}></img>
        </div>
        <Formik
          initialValues={{
            surname: "",
            email: "",
            message: "",
            phone: "",
            agreement: false,
          }}
          validateOnBlur
          onSubmit={(values, { resetForm }) => {
            setLoading(true);
            const mailData = {
              surname: values.surname,
              phone: values.phone,
              email: values.email,
              message: values.message,
            };
            mail
              .sendMail(mailData)
              .then(() => {
                setBtnHidden(true);
                setTimeout(() => {
                  setBtnHidden(false);
                }, 5000);
              })
              .catch((err) => {
                Promise.reject(err);
              })
              .finally(() => {
                setLoading(false);
                resetForm({ values: "" });
                setCheck(false);
              });
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <div className="contact-us_form">
              <input
                name="surname"
                className={`contact-us_form-input ${
                  errors.surname ? "error-input" : ""
                }`}
                placeholder={`${strings.contactUsForm.surname} *`}
                type={""}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname}
              />
              <div className="contact-us_form__phone__email">
                <input
                  name="phone"
                  ref={phoneRef}
                  className={`contact-us_form-input phone ${
                    errors.phone ? "error-input" : ""
                  }`}
                  placeholder={`${strings.contactUsForm.phone} *`}
                  onBlur={handleBlur}
                  onChange={(event) => phoneInputHandler(event, setFieldValue)}
                  value={values.phone}
                />
                <input
                  name="email"
                  className={`contact-us_form-input ${
                    errors.email ? "error-input" : ""
                  }`}
                  placeholder={`${strings.contactUsForm.email} *`}
                  type={"email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <textarea
                name="message"
                className={`contact-us_form-input ${
                  errors.message ? "error-input" : ""
                }`}
                placeholder={`${strings.contactUsForm.message} *`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              <div
                className={`contact-us_form_agreement ${
                  errors.agreement ? "error-checkbox" : ""
                }`}
                onClick={() => checkboxHandler(setFieldValue)}
              >
                <div
                  className={`contact-us_form_agreement-checkbox ${
                    check ? "contact-us_form_agreement__active" : ""
                  }`}
                  name="agreement"
                >
                  {check && <BsCheck />}
                </div>
                {strings.contactUsForm.agreement}
              </div>
              <Button
                text={`${strings.contactUsForm.btn}`}
                loading={loading}
                btnHidden={btnHidden}
                handler={() => handleSubmit()}
                classes={["contact-us_form_button"]}
              />
              {btnHidden && (
                <div className="send-message-response">
                  {strings.contactUsForm.sendMessageResponse}
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
