import tricolor from '../assets/tricolor.png'
import logo from '../assets/intro-logo.svg'

const casesSliderData = [
    {
        id: 1,
        image: tricolor,
        title: 'Увеличение скорости и качества обслуживания клиентов ',
        alias: 'Телеком, Продажи (В2С), CRM',
        task: 'Обновление Microsoft Dynamics CRM 2013 до Microsoft Dynamics CRM 365',
        solution: 'К 2021 году число конечных пользователей услуги выросло до 20 миллионов, расширилось количество предоставляемых услуг и использование устаревшей версии продукта значительно замедлило коммуникацию с клиентами и с партнерами. Требовалось совершенствование системы.  Обновление Dynamics CRM возможно только поэтапно через промежуточные версии. Поэтапное развертывание вызвало бы простой промышленного контура на срок от 5 часов минимум, а этого допускать было нельзя.   В сжатые сроки, без длительной остановки промышленного стенда был выполнен переход с системы MS Dynamics CRM 2013 на MS Dynamics 365 за одну итерацию. Тем самым удалось избежать длительного простоя и сохранить ресурсы прямых пользователей приложения. '
    },
    {
        id: 2,
        image: logo,
        title: 'Оптимизация времени на поиск решения в сложной мультинодной инфраструктуре, предотвращение сбоев в BI системе ',
        alias: 'Производство, предотвращение сбоев аналитической системы',
        task: 'Мониторинг системы аналитики на базе QLIK.',
        solution: 'Система мониторинга позволяет оперативно реагировать на возможные проблемы и ошибки всей архитектуры QLIK.  Разработанные инструменты и оснастка собирают всю необходимую информацию с серверов, на которых установлена BI система. В составе системы отслеживаются все задания на обновления каждого отчета, возникшие ошибки выводятся в режиме онлайн.   Информация выводится специалисту поддержки в виде одной страницы дашборда с индикаторами. Страница обновляется каждые 5 минут.   Такое решение подходит всем отраслям и сферам, существенно сокращает время на поиск ошибок в отчетности и позволяет в кратчайшие сроки реагировать на проблемы с инфраструктурой. Решение проблемы было предложено командой BI.  '
    },
    {
        id: 3,
        image: logo,
        title: 'Миграция сложных мультинодных и простых архитектур BI системы ',
        alias: 'Производство, архитектурные решения   ',
        task: 'В сжатые сроки осуществить перенос всех инсталляций аналитической системы без потери работоспособности и с минимальными затратами на ресурсы.  ',
        solution: 'В момент переноса в системе работало одномоментно более трехсот пользователей, для которых весь процесс должен был пройти незаметно. В рамках проекта командой было разработано решение последовательного переноса для каждого из 3-х компонентов аналитической системы без нарушения взаимодействия и работоспособности.   Когда первая часть компонента переезжала на новые сервера и домен, две другие работали в штатном режиме и получали необходимые данные из первой.   Также, командой были использованы сервера, оставшиеся от предыдущей аналитической системы, для переноса следующей. Был реализован конвейерный метод выделения ресурсов, что сократило суммарные ресурсы, которые нужны были для переноса более 10 серверных инсталляций.  '
    },
    {
        id: 4,
        image: logo,
        title: 'Переход на современную BI систему  ',
        alias: 'Производство, архитектурные решения    ',
        task: 'Миграция приложений QlikView в QlikSense.   ',
        solution: 'Перенос разработанных отчетов с QlikView на Qlik Sense. Проект проводился в течении 5 месяцев и включал в себя перенос более 10 приложений модели ETL с дальнейшей переработкой визуализаций.  Сложность заключалась в том, что разработанные приложения в QlikView имели сложные связи внутри и расчеты, которые требовалось оптимизировать.  Командой была доработана разветвленная модель показателей и введены новые показатели, расчеты которых были добавлены в перенесенные приложения. '
    },
    {
        id: 5,
        image: logo,
        title: 'Самообслуживание пользователей аналитической системы ',
        alias: 'Производство, решения оптимизации    ',
        task: 'Снизить нагрузку на техническую поддержку   ',
        solution: 'Разработка системы аналитических показателей для предоставления конечному бизнес пользователю возможности самому искать нужные ему показатели, способы расчетов. Сформирована система аналитических показателей — библиотека применяемых в аналитических отчетах показателей, расчетов, источников. Пользователь может самостоятельно найти все аналитические панели (дашборды), где визуализируется нужный ему показатель. Реализован расширенный поиск показателей, пользователю выводится вся необходимая информация, включая источники, формулы расчета, заказчики и информационные отчеты, в которых содержатся эти показатели. Система реализована в Web интерфейсе, может быть установлена как отдельное решение или встроена в корпоративный портал.  '
    },
    {
        id: 6,
        image: logo,
        title: 'Единое коммуникационное пространство для удаленных сотрудников ',
        alias: 'Информационные технологии, внутренние коммуникации ',
        task: 'Развернуть корпоративный портал на базе 365sharepoint за две недели   ',
        solution: 'Почти 2 года работы в удаленном формате потребовали создания общей коммуникационной площадки, также требовалась подготовка пространства для внедрения кадрового электронного документооборота. За две недели был собран портал из базовых компонентов 365sharepoint, была реализована оргструктура, новостной блок, дни рождения, заявки на отпуска и командировки, таймшиты (отчеты по ежедневной загрузке на проектах). '
    },
];

export default casesSliderData;