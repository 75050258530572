import reviewsSliderData from "../data/reviewsSliderData";
import "./reviews.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import strings from "../localization";
import updots from "../assets/updots.svg";
import { useEffect, useState } from "react";

const Reviews = (props) => {
  const [reviewsSliderData, setReviewsSliderData] = useState(
    strings.reviewsItems
  );

  const descriptionHendler = () => {
    props.checkHandler(true);
  };

  useEffect(() => {
    setReviewsSliderData(strings.reviewsItems);
  }, [props.language]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="reviews wrapper-item" id="reviews">
      <div className="heading wrapper-title">{strings.reviewsTitle}</div>
      <Slider {...settings} dotsClass="reviews_slider_dots">
        {reviewsSliderData.map((slide, index) => {
          return (
            <div className="reviews_slide-item" key={index}>
              <div
                className="reviews_slide-item_user-image"
                style={{ backgroundImage: `url(${slide.image})` }}
              ></div>
              <div className="reviews_slide-item--description--user">
                <div className="reviews_slide-item_description-updots">
                  <img src={updots}></img>
                </div>
                <div>
                  <div className="reviews_slide-item_description">
                    <div>
                      {slide.description[0]}
                      {slide.description[0].length > 0 ? (
                        <div
                          className="reviews_slide-item_description-more"
                          onClick={() => {
                            props.navBarHandler(true, slide.description);
                          }}
                        >
                          {strings.reviewsMore}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="reviews_slide-item_user">
                    <span>{slide.userName}</span>
                    <span>{slide.position}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Reviews;
