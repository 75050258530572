import "./statistic.scss";
import strings from "../localization";
import { useEffect, useState } from "react";


function Statistic(props) {

  const [items, setItems] = useState(strings.statisticItems);

  useEffect(() => {
    setItems(strings.statisticItems)
  }, [props.language])

  return (
    <div className="statistic wrapper-item">
      {items.map((m, i) => {
        return (
          <div className="statistic-item" key={i}>
            <div className="statistic-item__title" style={{
              color: m.color
            }}>{m.title}</div>
            <div className="statistic-item__desc">{m.desc}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Statistic;
