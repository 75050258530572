import "./partnersItem.scss";

function PartnersItem(props) {
  return (
    <div className="partners-item">
      <div className="partners-image-wrapper">
        <img src={props.image} className="partners-image-wrapper__image" alt="partner-img" />
      </div>
      
    </div>
  );
}

export default PartnersItem;
