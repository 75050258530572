import axios from 'axios';

const sendMail = (mailData) => {
  return new Promise((resolve, reject) => {
    axios.post("https://localhost:44354/api/mail/sendMail", {
      Surname: mailData.surname,
      Phone: mailData.phone,
      Email: mailData.email,
      Message: mailData.message
    }).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    })
  }); 
}

export default {
  sendMail
};