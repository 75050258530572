const Burger = (props) => {
    return(
        <svg className="burger" width="65" height="18" viewBox="0 0 65 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line className="burger-line" x1="0.705078" y1="1" x2="40.7051" y2="1" stroke="white" strokeWidth="2"/>
        <line className="burger-line" x1="0.705078" y1="9" x2="40.7051" y2="9" stroke="white" strokeWidth="2"/>
        <line className="burger-line" x1="0.705078" y1="17" x2="40.7051" y2="17" stroke="white" strokeWidth="2"/>
        </svg>
    )
}

export default Burger;