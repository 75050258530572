import "./intro.scss";
import introLogo from "../assets/intro-logo.svg";
import animationIntro from "../assets/animation_intro.svg";
import animationIntroLoop from "../assets/animation_intro_loop.svg";
import Button from "./button";
import AnimateLogo from "./animateLogo";
import strings from "../localization";

function IntroWidget() {

  const scrollToHandler = () => {
    const yOffset = -100;
    window.scrollTo({top: document.querySelector(`#contactUs`).getBoundingClientRect().top + window.pageYOffset + yOffset, behavior: 'smooth'});
  }

  return (
    <div className="intro" id="intro">
      <object type="image/svg+xml" data={animationIntroLoop} className="intro_animation intro_animation-loop" />
      <object type="image/svg+xml" data={animationIntro} className="intro_animation" /> 
      <AnimateLogo/>
      <div className="intro-logo">
        {/* <img src={introLogo} className="intro-logo__img" alt="logo" /> */}
      </div>
      <div className="intro-text">
        {strings.introText}
      </div>
      <Button
        text={`${strings.getDemonstration}`}
        handler={() => scrollToHandler()}
        classes={["intro-button"]}
      />
      <div className="intro-desc" dangerouslySetInnerHTML={{__html: strings.introDesc}}></div>
    </div>
  );
}

export default IntroWidget;
