import casesSliderData from "../data/casesSliderData.js";
import "./cases.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import SliderNav from "./sliderNav.js";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import strings from "../localization";

const Cases = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeCase, setActiveCase] = useState(null);
  const [slider, setSlider] = useState(null);

  const [casesSliderData, setCasesSliderData] = useState(strings.casesItems);

  const screenWidth = window.innerWidth;

  useEffect(() => {
    setCasesSliderData(strings.casesItems);
    if (props.selectCase) {
      const index = casesSliderData.findIndex(item => item.id === props.selectCase);
      if (screenWidth <= 680) {
        scrollHandler(`case-elem-${index}`);
        setActiveCase(casesSliderData[index])
      } else {
        scrollHandler(`cases`);
        setTimeout(() => {
          slider.slickGoTo(index);
          props.selectCaseHandler(null);
        }, 500)
      }
    }
  }, [props.selectCase, props.language]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
  };

  const scrollHandler = (selector) => {
    const yOffset = -100; 
    window.scrollTo({top: document.querySelector(`#${selector}`).getBoundingClientRect().top + window.pageYOffset + yOffset, behavior: 'smooth'});
  }


  const caseItemHandler = (item) => {
    if (activeCase?.id === item.id) setActiveCase(null);
    else setActiveCase(item);
  };

  return (
    <div className="cases wrapper-item" id="cases">
      <div className="cases--desctop">
        <div className="heading  wrapper-title">
          {strings.casesTitle}
          <SliderNav
            arrows={true}
            buttons={casesSliderData}
            slider={slider}
            currentSlide={currentSlide}
            btnClass="cases_slider-nav_btn"
          />
        </div>
        <Slider ref={(c) => setSlider(c)} {...settings}>
          {casesSliderData.map((slide, index) => {
            return (
              <div className="cases-slider-item" key={index}>
                <div className="border-wrap">
                  <div
                    className="cases-slider-item_logo"
                    style={{ backgroundImage: `url(${slide.image})` }}
                  ></div>
                </div>
                <div className="cases-slider-item_description">
                  <div className="cases-slider-item_description_title">
                    <span>{slide.title}</span>
                    <span>{slide.alias}</span>
                  </div>
                  <div className="cases-slider-item_description_item">
                    <div className="cases-slider-item_description_item-heading">
                      {strings.casesItemsTask}
                    </div>
                    {slide.task}
                  </div>
                  <div className="cases-slider-item_description_item">
                    <div className="cases-slider-item_description_item-heading">
                      {strings.caseItemsSolution}
                    </div>
                    {slide.solution}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="cases--mobile">
        <div className="heading">Кейсы</div>
        <div className="cases_items">
          {casesSliderData.map((item, index) => {
            return(
              <div className='cases_item' key={index}>
                <div className={`cases_item_title ${activeCase?.id === item.id ? 'cases_item__active' : ''}`} onClick={() => caseItemHandler(item)}  id={`case-elem-${index}`}>
                  <span style={{marginRight: "20px"}}>{item.title}</span>
                  <MdOutlineKeyboardArrowDown style={{transform: activeCase?.id === item.id ? 'rotate(180deg)' : ''}}/>
                </div>
                <div className={`cases-item_info ${activeCase?.id === item.id ? 'cases_item-info__active' : ''}`}>
                  <div className='cases-item_info_image--date'>
                    <div className='cases-item_info_image'>
                      <img src={item.image}></img>
                    </div>
                  </div>
                  <div className="cases-item_info_description_items">
                    <div className="cases-item_info_description_item">
                      <div className="cases-item_info_description_item-heading">
                        Задача
                      </div>
                      {item.task}
                    </div>
                    <div className="cases-item_info_description_item">
                      <div className="cases-item_info_description_item-heading">
                        Решение
                      </div>
                      {item.solution}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cases;
