import React from "react";
import "./App.scss";
import NavBar from "./components/navBar.js";
import Cases from "./components/cases";
import Curses from "./components/curses";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import Header from "./components/header";
import IntroWidget from "./components/intro";
import Partners from "./components/partners";
import Persons from "./components/persons";
import Plus from "./components/plus";
import Reviews from "./components/reviews";
import Services from "./components/services";
import Statistic from "./components/statistic";
import StsMap from "./components/stsMap";
import WorkUs from "./components/workUs";
import Progress from "./components/progress";
import strings from "./localization";

export const LOCALIZATION_KEY = "localizationKey";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.appRef = React.createRef();
    this.state = {
      burger: false,
      textModal: "",
      selectCase: null,
      language: "ru",
    };
  }

  handleScroll = (event) => {
    // let scrollTop = this.myRef.body.scrollTop;
    // console.log("this.myRef", this.appRef.current);
    // console.log("this.myRef", event.srcElement.body);
    // console.log("this.myRef", event.srcElement.body.scrollTop);
    // console.log("scrollTop", scrollTo  p);
  };

  navBarHandler = (status, textModal) => {
    this.setState({ burger: status, textModal: textModal });
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    await this.setState({
      language: localStorage.getItem(LOCALIZATION_KEY) ?? "ru",
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  selectCaseHandler = (caseId) => {
    this.setState({ selectCase: caseId });
  };

  render() {
    const handleLanguageChange = async () => {
      localStorage.setItem(
        LOCALIZATION_KEY,
        this.state.language === "ru" ? "en" : "ru"
      );
      await this.setState({ language: localStorage.getItem(LOCALIZATION_KEY) });
    };

    strings.setLanguage(localStorage.getItem(LOCALIZATION_KEY) ?? "ru");
    return (
      <React.Fragment>
        <div className="wrapper" ref={this.appRef}>
          <NavBar
            status={this.state.burger}
            textModal={this.state.textModal}
            navBarHandler={this.navBarHandler}
            language={this.state.language}
          />
          <Header
            navBarHandler={this.navBarHandler}
            language={this.state.language}
            handleLanguageChange={handleLanguageChange}
          />
          <IntroWidget />
          <Partners />
          <Statistic language={this.state.language} />
          <Progress />
          {/* <Persons language={this.state.language} /> */}
          <Services
            selectCaseHandler={this.selectCaseHandler}
            language={this.state.language}
          />
          <Plus language={this.state.language} />
          <WorkUs language={this.state.language} />
          <Cases
            selectCase={this.state.selectCase}
            selectCaseHandler={this.selectCaseHandler}
            language={this.state.language}
          />
          <Reviews
            language={this.state.language}
            navBarHandler={this.navBarHandler}
          />
        </div>
          <Curses/>
          <ContactUs />
        <StsMap />
        <Footer language={this.state.language} />
      </React.Fragment>
    );
  }
}

export default App;
