import { useEffect, useRef, useState } from "react";
import "./navBar.scss";
import { MdClose } from "react-icons/md";
import strings from "../localization";
import useWindowDimensions from "../utils/useWindowDimensions";

const NavBar = (props) => {
  const navItemsRef = useRef([]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setNavItems(strings.navBarItems);
    if (props.status) {
      document.querySelector("body").style.overflow = "hidden";
      navItemsRef.current.map((item, index) => {
        setTimeout(() => {
          item.classList.add("nav-item_animate");
        }, index * 50);
      });
    } else {
      navItemsRef.current.map((item) =>
        item.classList.remove("nav-item_animate")
      );
      document.querySelector("body").style.overflow = "";
    }
  }, [props.status, props.language]);

  const [navItems, setNavItems] = useState(strings.navBarItems);

  const navItemHandler = (item) => {
    if (item.path === "#footer") {
      document
        .querySelector(".test")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    } else {
      if (item.path) {
        const yOffset =
          (width <= 1000 ? item.smallOffset : item.offset) || -100;
        window.scrollTo({
          top:
            document.querySelector(`${item.path}`).getBoundingClientRect().top +
            window.pageYOffset +
            yOffset,
          behavior: "smooth",
        });
      }
    }
    props.navBarHandler();

    // if (item.path) {
    //   props.navBarHandler();
    //   const yOffset = -100;
    //   window.scrollTo({top: document.querySelector(`${item.path}`).getBoundingClientRect().top + window.pageYOffset + yOffset, behavior: 'smooth'});
    // }
  };

  return (
    <div
      className={`nav-bar ${props.status ? "nav-bar__active" : ""} ${
        props.textModal ? "nav-bar_textitems" : ""
      }`}
    >
      <MdClose
        className={`nav-bar__close ${props.status ? "nav-close_animate" : ""}`}
        onClick={() => props.navBarHandler(false)}
      />
      <div
        className={`nav-bar_items ${
          props.textModal ? "nav-bar_itemsdesc" : ""
        }`}
      >
        {props.textModal
          ? props.textModal.map((m, i) => {
              return (
                <div className="nav-bar_items_text" key={i}>
                  {m}
                  <br />
                  <br />
                  <br />
                </div>
              );
            })
          : navItems.map((item, index) => {
              //const offset = item.
              return (
                <div
                  className="nav-bar_item"
                  ref={(el) => (navItemsRef.current[index] = el)}
                  onClick={() => navItemHandler(item)}
                  key={index}
                >
                  {item.title}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default NavBar;
