import "./plus.scss";
import { useEffect, useRef, useState } from 'react';
import strings from "../localization";

function Plus(props) {

  const plusItemsRefs = useRef([]);

  const [items, setItems] = useState(strings.plusItems);

  useEffect(() => {
    setItems(strings.plusItems)
    window.addEventListener("scroll", (e) => {
      const windowTop = window.pageYOffset;
      const currentElem = document.querySelector(".plus").offsetTop - document.querySelector(".plus").scrollHeight;
      if (windowTop > currentElem) animatePersonItems();
    })
  }, [props.language]);

  const animatePersonItems = () => {
    plusItemsRefs.current.map((item, index) => {
      setTimeout(() => {
        item.classList.add("persons-item_animation");
      }, index * 150); 
    });
  }

  return (
    <div className="plus wrapper-item" id="plus">
      <div className="plus-title wrapper-title">{strings.plusTitle}</div>
      <div className="plus-items">
        {items.map((m, i) => {
          return (
            <div className={"plus-item " + ("plus-item_" + m.pname)} ref={el => plusItemsRefs.current[i] = el} key={i}>
              <div className="plus-item__img">
                <img
                  src={m.img.default}
                  className="plus-image-wrapper__image"
                  alt="partner-img"
                />
              </div>
              <div className="plus-item__title">{m.title}</div>
              <div className="plus-item__desc">{m.desc}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Plus;
