import { createRef, useEffect } from 'react';
import './animateLogo.scss';

const AnimateLogo = () => {

  const logo = createRef();
  const text = createRef();

  useEffect(() => {
    logo.current.querySelector('.first-line').classList.add('animation-first_line2');
    logo.current.querySelector('.second-line').classList.add('animation-second_line2');
    logo.current.querySelector('.third-line').classList.add('animation-third_line2');
    [...text.current.querySelectorAll('.main_item')].map((item, index) => {
      setTimeout(()=>{
        item.classList.add('animation-main_item')
      }, index * 200)
    });
    [...text.current.querySelectorAll('.second_item')].map(item => {
      item.classList.add('animation-second_item')
    })
  }, []);

  return(
    <div className="animate-logo-wrapper">
      <svg className="animate-logo" ref={logo} width="270" viewBox="0 0 270 270" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="item first-line" d="M179.059 20.3225C181.465 14.0586 178.349 6.97287 171.894 5.13902C151.482 -0.659996 129.931 -1.58117 108.998 2.5278C84.0784 7.41909 61.044 19.2399 42.54 36.6325C24.0361 54.0252 10.8132 76.2843 4.38984 100.853C-2.03347 125.423 -1.39663 151.305 6.22722 175.529C13.8511 199.752 28.1527 221.334 47.4896 237.796C66.8265 254.257 90.4144 264.931 115.544 268.591C140.674 272.251 166.326 268.748 189.555 258.486C209.069 249.865 226.208 236.767 239.618 220.323C243.859 215.123 242.356 207.529 236.796 203.773C231.236 200.016 223.731 201.531 219.388 206.646C208.609 219.343 195.066 229.485 179.735 236.258C160.688 244.673 139.653 247.545 119.046 244.544C98.4398 241.543 79.0978 232.791 63.2415 219.292C47.3853 205.794 35.6579 188.097 29.4063 168.233C23.1548 148.37 22.6326 127.146 27.8997 107C33.1668 86.8532 44.0096 68.6007 59.1828 54.3387C74.356 40.0767 93.2443 30.3837 113.678 26.3728C130.124 23.1447 147.035 23.7027 163.143 27.9371C169.633 29.6431 176.652 26.5863 179.059 20.3225Z" fill="#3462A5"/>
        <path className="item second-line" d="M134.626 183.817C134.626 189.58 139.333 194.345 145.008 193.341C155.031 191.569 164.495 187.248 172.452 180.717C183.116 171.966 190.415 159.788 193.106 146.258C195.797 132.729 193.714 118.684 187.211 106.518C180.709 94.3526 170.189 84.8178 157.444 79.5388C144.699 74.2597 130.518 73.5631 117.317 77.5675C104.117 81.5719 92.7128 90.0296 85.0488 101.5C77.3848 112.969 73.935 126.742 75.2871 140.47C76.2961 150.715 79.9327 160.462 85.767 168.803C89.0702 173.525 95.7674 173.484 99.8424 169.41C103.917 165.335 103.762 158.766 100.918 153.754C98.2621 149.073 96.5907 143.857 96.0557 138.425C95.1768 129.501 97.4192 120.549 102.401 113.094C107.382 105.638 114.795 100.141 123.375 97.5379C131.956 94.9351 141.174 95.3879 149.458 98.8193C157.742 102.251 164.58 108.448 168.806 116.356C173.033 124.264 174.387 133.393 172.638 142.187C170.889 150.981 166.144 158.897 159.213 164.585C154.994 168.048 150.123 170.555 144.935 171.987C139.38 173.52 134.626 178.055 134.626 183.817Z" fill="#5CCBE5"/>
        <path className="item third-line" d="M48.1027 128.003C41.571 127.515 35.7993 132.42 36.1037 138.962C36.8344 154.671 41.4167 170.035 49.5357 183.711C59.6875 200.812 74.8872 214.461 93.1114 222.842C111.336 231.224 131.72 233.939 151.55 230.628C171.38 227.317 189.716 218.135 204.116 204.306C218.517 190.477 228.298 172.656 232.159 153.215C236.02 133.775 233.777 113.638 225.728 95.4845C217.68 77.331 204.208 62.0225 187.105 51.5969C173.338 43.2041 157.744 38.2963 141.707 37.2239C135.252 36.7923 130.264 42.3672 130.596 48.8277C130.928 55.2742 136.443 60.1412 142.863 60.8057C154.075 61.966 164.93 65.5988 174.6 71.4936C187.598 79.4171 197.836 91.0515 203.953 104.848C210.07 118.645 211.775 133.949 208.841 148.724C205.907 163.498 198.473 177.042 187.528 187.552C176.584 198.063 162.649 205.041 147.578 207.557C132.507 210.074 117.015 208.01 103.165 201.64C89.3143 195.27 77.7625 184.897 70.0471 171.901C64.376 162.348 60.9754 151.711 60.031 140.784C59.4652 134.237 54.6556 128.493 48.1027 128.003Z" fill="#1FA3CA"/>
      </svg>
      <svg ref={text} className="animate-logo_text" width="553" viewBox="0 0 553 294" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="main_item" d="M101.13 28.0158L81.2951 39.6264C79.6817 35.7558 77.2631 32.3704 74.0391 29.4664C69.2004 25.5971 63.3964 23.6611 56.6231 23.6611C49.3657 23.6611 43.7617 25.5171 39.8124 29.2251C35.8591 32.9371 33.8857 37.5331 33.8857 43.0131C33.8857 51.3998 39.6911 57.9304 51.3018 62.6051L67.5084 69.1358C92.8231 79.4584 105.484 95.2598 105.484 116.545C105.484 131.868 100.604 144.325 90.8497 153.917C81.0911 163.513 68.6338 168.31 53.4791 168.31C37.8351 168.31 25.1777 162.909 15.5017 152.102C7.60042 143.237 3.08309 132.752 1.95642 120.657L26.3871 115.337C26.3871 123.077 28.6431 129.933 33.1604 135.897C38.3191 142.349 45.3337 145.572 54.2044 145.572C61.7817 145.572 68.1124 143.073 73.1924 138.073C78.2711 133.077 80.8111 126.546 80.8111 118.481C80.8111 111.066 78.6724 105.34 74.4017 101.306C70.1271 97.2784 64.5217 93.8091 57.5911 90.9051L42.1097 84.3758C20.1778 75.1838 9.21375 61.5584 9.21375 43.4958C9.21375 31.8864 13.4458 21.8891 21.9124 13.5024C30.3791 5.11977 41.8684 0.925095 56.3804 0.925095C69.4431 0.925095 80.2444 4.5531 88.7937 11.8091C93.7897 16.3251 97.9031 21.7264 101.13 28.0158Z" fill="#3462A5"/>
        <path className="main_item" d="M132.09 165.166V63.3309H155.794V72.7642C162.405 64.7029 170.79 60.6695 180.951 60.6695C192.721 60.6695 201.51 65.6709 207.315 75.6669C214.247 65.6709 224.327 60.6695 237.551 60.6695C250.773 60.6695 260.449 65.9922 266.578 76.6349C270.122 82.9242 271.899 91.4722 271.899 102.275V165.166H248.194V109.048C248.194 100.182 247.469 94.1335 246.017 90.9055C243.758 84.9429 239.245 81.9562 232.471 81.9562C226.667 81.9562 221.987 84.4589 218.443 89.4549C215.378 93.8082 213.846 101.55 213.846 112.676V165.166H190.142V109.048C190.142 100.182 189.417 94.1335 187.965 90.9055C185.705 84.9429 181.193 81.9562 174.419 81.9562C168.615 81.9562 163.935 84.4589 160.39 89.4549C157.325 93.8082 155.794 101.55 155.794 112.676V165.166H132.09Z" fill="#3462A5"/>
        <path className="main_item" d="M349.059 81.9565C340.672 81.9565 333.859 85.1005 328.62 91.3898C323.377 97.6792 320.759 105.419 320.759 114.611C320.759 123.644 323.377 131.3 328.62 137.59C333.859 143.879 340.672 147.023 349.059 147.023C357.121 147.023 363.935 144.204 369.499 138.558C375.061 132.915 377.844 125.012 377.844 114.852C377.844 104.372 375.061 96.2685 369.499 90.5432C363.935 84.8205 357.121 81.9565 349.059 81.9565ZM376.392 75.1832V63.3312H400.096V165.166H376.392V154.522C368.489 163.714 358.331 168.31 345.915 168.31C330.919 168.31 318.944 162.988 309.995 152.346C301.044 141.702 296.571 128.964 296.571 114.127C296.571 98.0045 301.365 85.0632 310.963 75.3045C320.555 65.5498 332.287 60.6698 346.156 60.6698C358.893 60.6698 368.973 65.5085 376.392 75.1832Z" fill="#3462A5"/>
        <path className="main_item" d="M430.091 165.166V63.3309H453.795V72.5229C460.406 64.6242 468.389 60.6695 477.742 60.6695C484.19 60.6695 490.563 62.6882 496.851 66.7175L486.209 88.2455C481.529 85.0215 477.097 83.4082 472.905 83.4082C466.29 83.4082 461.294 86.0682 457.907 91.3895C455.165 95.7442 453.795 102.759 453.795 112.434V165.166H430.091Z" fill="#3462A5"/>
        <path className="main_item" d="M552.965 85.1009H534.824V165.165H511.118V85.1009H501.201V63.3303H511.118V26.0809H534.824V63.3303H552.965V85.1009Z" fill="#3462A5"/>
        <path className="second_item" d="M43.3138 230.672H27.3791V290.14H16.7578V230.672H0.82312V220.675H43.3138V230.672Z" fill="#3462A5"/>
        <path className="second_item" d="M55.4987 262.332H75.8067C75.2507 259.904 74.0534 257.959 72.2147 256.5C70.3734 255.043 68.2041 254.314 65.7054 254.314C63.2054 254.314 61.0534 255.043 59.2481 256.5C57.4427 257.959 56.1921 259.904 55.4987 262.332ZM78.0987 273.894L86.6374 278.684C85.0401 281.74 83.1321 284.239 80.9107 286.183C76.8827 289.723 71.9881 291.494 66.2254 291.494C60.2534 291.494 55.1161 289.515 50.8121 285.558C46.5067 281.599 44.3561 275.908 44.3561 268.476C44.3561 261.535 46.2987 255.912 50.1881 251.606C54.0747 247.303 59.2481 245.148 65.7054 245.148C72.9254 245.148 78.4107 247.822 82.1601 253.168C84.8681 257.058 86.2214 262.368 86.2214 269.102V270.664H54.7694C54.9081 274.415 56.1761 277.296 58.5707 279.308C60.9667 281.323 63.6561 282.328 66.6427 282.328C69.3507 282.328 71.6747 281.531 73.6201 279.934C75.5627 278.475 77.0561 276.463 78.0987 273.894Z" fill="#3462A5"/>
        <path className="second_item" d="M117.569 254.314C113.958 254.314 111.024 255.667 108.768 258.375C106.512 261.083 105.384 264.415 105.384 268.373C105.384 272.262 106.512 275.559 108.768 278.267C111.024 280.974 113.958 282.329 117.569 282.329C121.04 282.329 123.974 281.115 126.369 278.683C128.764 276.254 129.962 272.851 129.962 268.477C129.962 263.965 128.764 260.475 126.369 258.01C123.974 255.547 121.04 254.314 117.569 254.314ZM129.337 251.397V246.294H139.542V290.139H129.337V285.558C125.934 289.514 121.56 291.494 116.214 291.494C109.758 291.494 104.602 289.202 100.749 284.619C96.8957 280.038 94.969 274.554 94.969 268.165C94.969 261.223 97.0343 255.651 101.166 251.45C105.296 247.25 110.346 245.149 116.32 245.149C121.804 245.149 126.144 247.231 129.337 251.397Z" fill="#3462A5"/>
        <path className="second_item" d="M152.457 290.14V246.294H162.662V250.356C165.509 246.886 169.12 245.149 173.494 245.149C178.561 245.149 182.346 247.302 184.846 251.606C187.83 247.302 192.17 245.149 197.865 245.149C203.556 245.149 207.722 247.441 210.362 252.022C211.888 254.73 212.652 258.412 212.652 263.061V290.14H202.446V265.978C202.446 262.161 202.134 259.557 201.509 258.168C200.537 255.6 198.594 254.314 195.677 254.314C193.177 254.314 191.162 255.39 189.637 257.542C188.318 259.417 187.657 262.749 187.657 267.541V290.14H177.452V265.978C177.452 262.161 177.14 259.557 176.514 258.168C175.541 255.6 173.598 254.314 170.682 254.314C168.182 254.314 166.168 255.39 164.642 257.542C163.321 259.417 162.662 262.749 162.662 267.541V290.14H152.457Z" fill="#3462A5"/>
        <path className="second_item" d="M290.24 231.089L281.7 236.089C281.004 234.422 279.962 232.963 278.574 231.714C276.492 230.049 273.992 229.215 271.077 229.215C267.952 229.215 265.54 230.013 263.838 231.61C262.137 233.209 261.286 235.186 261.286 237.546C261.286 241.158 263.786 243.969 268.785 245.982L275.764 248.794C286.662 253.238 292.113 260.042 292.113 269.206C292.113 275.803 290.013 281.167 285.813 285.297C281.61 289.429 276.248 291.494 269.722 291.494C262.988 291.494 257.538 289.169 253.372 284.515C249.969 280.698 248.025 276.183 247.54 270.977L258.058 268.685C258.058 272.018 259.03 274.97 260.974 277.538C263.196 280.315 266.216 281.703 270.036 281.703C273.297 281.703 276.024 280.627 278.21 278.475C280.397 276.325 281.49 273.511 281.49 270.039C281.49 266.846 280.57 264.382 278.73 262.645C276.89 260.91 274.478 259.417 271.493 258.167L264.828 255.355C255.384 251.397 250.664 245.531 250.664 237.754C250.664 232.755 252.488 228.451 256.132 224.839C259.776 221.23 264.724 219.425 270.973 219.425C276.597 219.425 281.246 220.987 284.928 224.113C287.08 226.057 288.849 228.382 290.24 231.089Z" fill="#3462A5"/>
        <path className="second_item" d="M312.422 262.332H332.729C332.173 259.904 330.975 257.959 329.137 256.5C327.295 255.043 325.127 254.314 322.627 254.314C320.127 254.314 317.975 255.043 316.171 256.5C314.365 257.959 313.115 259.904 312.422 262.332ZM335.021 273.894L343.561 278.684C341.962 281.74 340.054 284.239 337.834 286.183C333.805 289.723 328.91 291.494 323.149 291.494C317.175 291.494 312.039 289.515 307.734 285.558C303.429 281.599 301.278 275.908 301.278 268.476C301.278 261.535 303.222 255.912 307.11 251.606C310.998 247.303 316.171 245.148 322.627 245.148C329.847 245.148 335.334 247.822 339.082 253.168C341.79 257.058 343.145 262.368 343.145 269.102V270.664H311.693C311.831 274.415 313.098 277.296 315.494 279.308C317.89 281.323 320.579 282.328 323.565 282.328C326.273 282.328 328.598 281.531 330.543 279.934C332.486 278.475 333.979 276.463 335.021 273.894Z" fill="#3462A5"/>
        <path className="second_item" d="M354.182 290.14V246.294H364.388V250.252C367.234 246.85 370.672 245.149 374.7 245.149C377.476 245.149 380.218 246.018 382.926 247.753L378.344 257.021C376.329 255.633 374.421 254.94 372.616 254.94C369.768 254.94 367.617 256.084 366.158 258.376C364.978 260.25 364.388 263.27 364.388 267.437V290.14H354.182Z" fill="#3462A5"/>
        <path className="second_item" d="M382.925 246.294H394.381L405.627 271.289L416.875 246.294H428.333L405.627 293.265L382.925 246.294Z" fill="#3462A5"/>
        <path className="second_item" d="M435.413 246.295H445.619V290.14H435.413V246.295ZM435.829 232.86C434.58 231.609 433.954 230.048 433.954 228.173C433.954 226.299 434.58 224.736 435.829 223.487C437.08 222.237 438.641 221.612 440.516 221.612C442.39 221.612 443.953 222.237 445.203 223.487C446.452 224.736 447.077 226.299 447.077 228.173C447.077 230.048 446.452 231.609 445.203 232.86C443.953 234.109 442.39 234.735 440.516 234.735C438.641 234.735 437.08 234.109 435.829 232.86Z" fill="#3462A5"/>
        <path className="second_item" d="M492.483 248.274V261.708C489.635 256.779 485.4 254.314 479.778 254.314C476.235 254.314 473.164 255.564 470.56 258.063C467.955 260.563 466.655 263.966 466.655 268.268C466.655 272.227 467.851 275.559 470.247 278.267C472.643 280.974 475.784 282.328 479.674 282.328C482.24 282.328 484.672 281.67 486.962 280.351C489.254 279.031 491.092 277.191 492.483 274.83V288.264C488.732 290.415 484.706 291.494 480.4 291.494C472.834 291.494 466.863 289.272 462.488 284.828C458.323 280.523 456.239 275.038 456.239 268.372C456.239 261.36 458.599 255.599 463.322 251.084C467.488 247.128 473.075 245.148 480.09 245.148C484.88 245.148 489.011 246.191 492.483 248.274Z" fill="#3462A5"/>
        <path className="second_item" d="M511.125 262.332H531.432C530.876 259.904 529.678 257.959 527.84 256.5C525.998 255.043 523.83 254.314 521.33 254.314C518.83 254.314 516.678 255.043 514.874 256.5C513.068 257.959 511.818 259.904 511.125 262.332ZM533.724 273.894L542.264 278.684C540.665 281.74 538.757 284.239 536.537 286.183C532.508 289.723 527.613 291.494 521.852 291.494C515.878 291.494 510.742 289.515 506.437 285.558C502.132 281.599 499.981 275.908 499.981 268.476C499.981 261.535 501.925 255.912 505.813 251.606C509.701 247.303 514.874 245.148 521.33 245.148C528.55 245.148 534.037 247.822 537.785 253.168C540.493 257.058 541.848 262.368 541.848 269.102V270.664H510.396C510.534 274.415 511.801 277.296 514.197 279.308C516.593 281.323 519.282 282.328 522.268 282.328C524.976 282.328 527.301 281.531 529.246 279.934C531.189 278.475 532.682 276.463 533.724 273.894Z" fill="#3462A5"/>
      </svg>
    </div>
  )
}

export default AnimateLogo;