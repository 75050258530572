import './progress.scss';
import ProgressBar from 'react-animated-progress-bar';
import strings from "../localization";

const Progress = () => {

  return(
    <div className="progress wrapper-item">
      <ProgressBar width="280" trackWidth="13" percentage="80" trackPathColor="transparent" trackBorderColor="transparent"/>
      <svg xmlns="http://www.w3.org/2000/svg" className="progress-linear-color" version="1.1">
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stopColor="#5CCBE5" />
            <stop offset="100%" stopColor="#3462A5" />
          </linearGradient>
        </defs>
      </svg>
      <div className="progress-info" dangerouslySetInnerHTML={{__html: strings.progressInfo}}></div>
    </div>
  )
}

export default Progress;
