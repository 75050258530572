import "./workUs.scss";
import workUsData from "../data/workUsData";
import SliderNav from "./sliderNav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import strings from "../localization";
import { browserName } from "react-device-detect";

const WorkUs = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider, setSlider] = useState(null);
  const [slidesShow, setSlidesShow] = useState(1);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 850 && window.innerWidth > 590) {
      transformArray(strings.workUsData, 2);
    } else if (window.innerWidth <= 590) {
      transformArray(strings.workUsData, 1);
    } else {
      transformArray(strings.workUsData, 3);
    }
  }, [props.language]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesShow,
    afterChange: (current) => setCurrentSlide(current),
    arrows: false,
  };

  const transformArray = (array, sizeArray) => {
    let size = sizeArray;
    let subarray = [];
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      subarray[i] = array.slice(i * size, i * size + size);
    }
    console.log("subarray", subarray);
    setSlides(subarray);
  };

  const prevSlide = () => {
    slider.slickPrev();
  };

  const nextSlide = () => {
    slider.slickNext();
  };

  return (
    <div className="work-us wrapper-item" id="work-us">
      <div className="heading wrapper-title">
        {strings.workUsTitle}
        <MdKeyboardArrowLeft
          className="work-us_items_slide-nav left"
          onClick={prevSlide}
        />
        <MdKeyboardArrowRight
          className="work-us_items_slide-nav"
          onClick={nextSlide}
        />
        <SliderNav
          arrows={true}
          buttons={Array.from({ length: slides.length })}
          slider={slider}
          currentSlide={currentSlide}
          class="work-us_slide-nav"
          btnClass="work-us_slide-nav_buttons"
        />
      </div>
      <div className="work-us_items">
        <Slider ref={(c) => setSlider(c)} {...settings}>
          {slides.map((slide, index) => {
            return (
              <div key={index}>
                <div
                  className="work-us_slide"
                  style={{
                    justifyContent: slide.length === 3 ? "space-between" : "",
                  }}
                >
                  {slide.map((item, index) => {
                    return (
                      <div
                        className="work-us_item"
                        style={{ backgroundImage: `url(${item.image})` }}
                        key={index}
                      >
                        <div
                          className={
                            `work-us_item_description ` +
                            (browserName !== "Chrome" &&
                            browserName !== "Yandex"
                              ? ` work-us_item_description_safari`
                              : ` `)
                          }
                        >
                          {item.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default WorkUs;
