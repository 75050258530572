module.exports = [
  {
    id: 11,
    bg: require("../assets/personbg1.svg"),
    image1: require("../assets/personPic1.png"),
    image2: require("../assets/personPic2.png"),
    blotPath: `M 154.856 0.5091 C 188.173 0.6022 217.389 21.2087 239.482 46.1477 C 260.174 69.5043 266.817 99.7606 270.256 130.774 C 274.312 167.344 284.732 208.743 260.595 236.514 C 235.786 265.058 192.632 261.729 154.856 263.545 C 114.165 265.501 69.2133 273.883 38.4379 247.192 C 9 214 5 205 2 185 C 0 161 1 144 7 128 C 16 109 17 106 30 89 C 51 67 49 65 71 45 C 95.1807 24.0379 121.471 0.4157 154.856 0.5091 Z`,
    secondBlotPath: `M167.427 0.383067C195.39 -1.13638 224.153 6.61803 245.786 24.4186C266.756 41.6723 273.156 69.4417 282.405 94.988C292.027 121.562 303.464 147.574 300.556 175.689C297.126 208.842 293.592 249.861 264.674 266.379C235.488 283.051 200.745 256.103 167.427 251.734C140.049 248.144 111.911 255.184 87.081 243.092C58.947 229.392 34.3326 208.268 20.492 180.18C5.2438 149.235 -8.76733 110.928 6.78349 80.1345C22.2289 49.5492 64.3434 47.8377 95.0138 32.6114C119.273 20.5679 140.388 1.85231 167.427 0.383067Z`,
    name: "Побединская Татьяна",
    desc: "Исполнительный директор",
  },
  {
    id: 12,
    bg: require("../assets/personbg1.svg"),
    image1: require("../assets/personPic1.png"),
    image2: require("../assets/personPic2.png"),
    blotPath: `M143.318 9.88643C171.685 5.26275 202.523 -7.33657 227.386 7.0685C252.653 21.7072 264.09 53.2618 268.076 82.1681C271.677 108.277 255.291 131.316 248.012 156.648C240.366 183.257 245.14 215.795 224.402 234.157C203.306 252.835 171.48 248.112 143.318 249.344C113.697 250.64 79.4132 260.764 56.9057 241.484C34.4338 222.234 45.4832 185.515 36.0725 157.477C26.5195 129.014 -7.71081 105.206 1.58445 76.658C10.8802 48.1088 49.8876 44.3747 77.0683 31.5697C98.6139 21.4195 119.808 13.7185 143.318 9.88643Z`,
    secondBlotPath: `M167.427 0.383067C195.39 -1.13638 224.153 6.61803 245.786 24.4186C266.756 41.6723 273.156 69.4417 282.405 94.988C292.027 121.562 303.464 147.574 300.556 175.689C297.126 208.842 293.592 249.861 264.674 266.379C235.488 283.051 200.745 256.103 167.427 251.734C140.049 248.144 111.911 255.184 87.081 243.092C58.947 229.392 34.3326 208.268 20.492 180.18C5.2438 149.235 -8.76733 110.928 6.78349 80.1345C22.2289 49.5492 64.3434 47.8377 95.0138 32.6114C119.273 20.5679 140.388 1.85231 167.427 0.383067Z`,
    name: "Кужелев Андрей",
    desc: "Директор по развитию",
  },
  {
    id: 13,
    bg: require("../assets/personbg1.svg"),
    image1: require("../assets/personPic1.png"),
    image2: require("../assets/personPic2.png"),
    blotPath: `M139.31 13.1165C165.189 15.0869 192.457 4.79437 215.147 17.3951C239.04 30.6634 254.858 55.503 262.752 81.6676C270.539 107.478 264.57 134.219 258.304 160.44C251.476 189.014 246.577 219.776 224.987 239.701C202.146 260.781 170.295 267.806 139.31 270.258C106.105 272.886 67.3695 276.987 43.1783 254.09C19.2055 231.4 30.1577 191.836 22.5262 159.722C15.7638 131.265 -4.88066 105.496 1.06118 76.8566C7.27142 46.9237 26.8294 17.9706 54.5911 5.17094C80.9949 -7.00264 110.319 10.9092 139.31 13.1165Z`,
    secondBlotPath: `M167.427 0.383067C195.39 -1.13638 224.153 6.61803 245.786 24.4186C266.756 41.6723 273.156 69.4417 282.405 94.988C292.027 121.562 303.464 147.574 300.556 175.689C297.126 208.842 293.592 249.861 264.674 266.379C235.488 283.051 200.745 256.103 167.427 251.734C140.049 248.144 111.911 255.184 87.081 243.092C58.947 229.392 34.3326 208.268 20.492 180.18C5.2438 149.235 -8.76733 110.928 6.78349 80.1345C22.2289 49.5492 64.3434 47.8377 95.0138 32.6114C119.273 20.5679 140.388 1.85231 167.427 0.383067Z`,
    name: "Гостева Мария",
    desc: "Руководитель программ по управлению персоналом",
  },
  {
    id: 14,
    bg: require("../assets/personbg1.svg"),
    image1: require("../assets/personPic1.png"),
    image2: require("../assets/personPic2.png"),
    blotPath: `M143.182 2.29338C173.681 4.68553 204.065 11.7939 228.064 30.7655C251.448 49.2515 266.049 76.3934 273.446 105.27C280.497 132.799 273.365 160.302 268.409 188.284C262.522 221.523 267.331 261.266 241.887 283.451C216.211 305.838 177.214 298.989 143.182 297.467C110.683 296.014 75.3579 296.109 50.6885 274.902C26.6761 254.26 25.3312 219.159 16.6611 188.704C8.37744 159.607 -4.104 131.265 1.3243 101.502C7.26311 68.9405 20.918 35.6147 48.0421 16.6454C74.984 -2.19656 110.406 -0.277449 143.182 2.29338Z`,
    secondBlotPath: `M167.427 0.383067C195.39 -1.13638 224.153 6.61803 245.786 24.4186C266.756 41.6723 273.156 69.4417 282.405 94.988C292.027 121.562 303.464 147.574 300.556 175.689C297.126 208.842 293.592 249.861 264.674 266.379C235.488 283.051 200.745 256.103 167.427 251.734C140.049 248.144 111.911 255.184 87.081 243.092C58.947 229.392 34.3326 208.268 20.492 180.18C5.2438 149.235 -8.76733 110.928 6.78349 80.1345C22.2289 49.5492 64.3434 47.8377 95.0138 32.6114C119.273 20.5679 140.388 1.85231 167.427 0.383067Z`,
    name: "Солодовникова Екатерина",
    desc: "Руководитель практики",
  },
  {
    id: 15,
    bg: require("../assets/personbg1.svg"),
    image1: require("../assets/personPic1.png"),
    image2: require("../assets/personPic2.png"),
    blotPath: `M134.093 26.6121C163.806 19.6351 196.974 -12.5113 221.059 6.23507C245.462 25.2287 214.278 67.3668 224.118 96.6825C234.139 126.537 276.689 140.811 276.802 172.302C276.917 204.443 253.37 236.133 224.668 250.598C196.962 264.561 165.059 246.155 134.093 244.221C105.845 242.458 75.1628 255.124 51.379 239.78C27.2738 224.229 20.2042 193.087 12.1546 165.554C4.33958 138.823 -6.22937 109.347 5.91967 84.2871C17.744 59.8964 47.758 52.2476 72.4765 41.1249C92.2019 32.2489 113.036 31.5568 134.093 26.6121Z`,
    secondBlotPath: `M167.427 0.383067C195.39 -1.13638 224.153 6.61803 245.786 24.4186C266.756 41.6723 273.156 69.4417 282.405 94.988C292.027 121.562 303.464 147.574 300.556 175.689C297.126 208.842 293.592 249.861 264.674 266.379C235.488 283.051 200.745 256.103 167.427 251.734C140.049 248.144 111.911 255.184 87.081 243.092C58.947 229.392 34.3326 208.268 20.492 180.18C5.2438 149.235 -8.76733 110.928 6.78349 80.1345C22.2289 49.5492 64.3434 47.8377 95.0138 32.6114C119.273 20.5679 140.388 1.85231 167.427 0.383067Z`,
    name: "Баукина Валерия",
    desc: "Архитектор",
  }
];
